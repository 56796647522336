"use client";

import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import NavBar from "./NavBar";
import { defaultTheme } from "../theme/themes";
//import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import Footer from "./Footer";
import { Suspense, useState } from "react";
import LoadingPage from "../loading";
import Grid from "@mui/material/Grid";
import UserDetailProvider from "../context/userDetailContext";
import SearchQueryProvider from "../context/searchQueryContext";
import { SessionProvider } from "next-auth/react";
import AdditionalDataModal from "./AdditionalDataForm";
import "../../app/globals.css";
import NotificationProvider from "../context/useNotificationContext";
import ErrorBoundary from "./ErrorBoundary";

//Amplify.configure({ ...awsExports, ssr: true });

export default function ProvidersWrapper({ children }) {
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <SessionProvider>
          <ErrorBoundary>
            <Authenticator.Provider>
              <NotificationProvider>
                <SearchQueryProvider>
                  <UserDetailProvider>
                    <Box sx={{
                      background: '#fff !important',
                      maxWidth: '1560px',
                      width: '100%',
                      margin: '0 auto',
                      position: 'relative',
                    }}>
                      <NavBar />
                      <Suspense fallback={<LoadingPage />}>
                        <Grid container minHeight={"85vh"}
                        // marginTop={10}
                        >
                          <Grid item xs={12}>
                            {children}
                            <AdditionalDataModal />
                          </Grid>
                        </Grid>
                      </Suspense>
                      <Footer />
                    </Box>
                  </UserDetailProvider>
                </SearchQueryProvider>
              </NotificationProvider>
            </Authenticator.Provider>
          </ErrorBoundary>
        </SessionProvider>
      </ThemeProvider>
    </>
  );
}
