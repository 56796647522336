//auth
export const authRoute = () => `/auth`


//Public Routes
export const businessListRoute = (communityAlias) => `/${communityAlias}/business`;
export const businessRoute = (communityAlias, businessId, name) => {
    const businessName = name.replace(/\s+/g, '-').toLowerCase()
    return `/${communityAlias}/${businessName}/${businessId}`
}
export const createBusinesRoute = (communityAlias) => `/${communityAlias}/business/create-business`;
export const profileRoute = (communityAlias, profileAlias) => `/${communityAlias}/profile/${profileAlias}`

//Admin Routes
export const communityAdminRoute = () => `/admin/community`
export const createCategoryAdminRoute = () => `/admin/category/create-category`
export const claimedBusinessListAdminRoute = () => `/admin/claimed-businesses`
export const enableBusinessListAdminRoute = () => `/admin/enable-businesses`
export const recommendedCommunityListAdminRoute = () => `/admin/community/recommend-communities`
export const verifyIdListAdminRoute = () => `/admin/verify-ids`
export const createDataAdminRoute = () => `/admin/create-data`

//Moderator Routes
export const flaggedReviewListModeratorRoute = () => `/moderator/flagged-reviews`
