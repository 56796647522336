import { useEffect, useState } from "react"
import { VerificationStatus } from '@prisma/client';
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import MenuItem from "@mui/material/MenuItem"
import { useRouter } from "next/navigation"
import { useUserData } from "@/app/context/userDetailContext"
import CheckLoggedIn from "@/app/components/ui-components/CheckLoggedInModal"
import Image from "next/image"
import { myLoader } from "@/app/utils/custom_loader"
import { useSearchQuery } from "@/app/context/searchQueryContext"
import { businessListRoute, claimedBusinessListAdminRoute, communityAdminRoute, createBusinesRoute, createCategoryAdminRoute, createDataAdminRoute, enableBusinessListAdminRoute, flaggedReviewListModeratorRoute, profileRoute, recommendedCommunityListAdminRoute, verifyIdListAdminRoute } from "../routes/routes"
import ExpandableSearch from "@/app/components/ui-components/ExpandableSearch"
import { URL_LOGIN, URL_LOGOUT } from "@/lib/constants"
import { getCommunityById } from "@/db/community"
import { useSession } from "next-auth/react"
import { AUTHENTICATED, USERTYPE_ADMINISTRATOR } from "@/lib/constants"

const pages = [
  "Home",
  "Administrator",
  "Moderator",
  "Businesses",
  "Add Business",
  "Profile",
  "Logout",
  "Login",
];

const settings = ["Profile", "Logout"]

function NavBar() {
  const router = useRouter();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElAdmin, setAnchorElAdmin] = useState(null);
  const [anchorElModerator, setAnchorElModerator] = useState(null);
  const { setQuery } = useSearchQuery();
  const session = useSession()
  const isAuthenticated = session?.status == AUTHENTICATED

  const { isAdmin, userData, user, isModerator, loggedInUserOperationOnly, setAskForDetails } = useUserData();
  const [openSendToLoginModal, setOpenSendToLoginModal] = useState(false);


  /*
  useEffect(() => {
    const user = session?.data?.user
    if (!!user?.firstName || !!user?.lastName || !!user?.alias || !!user?.communityId || !!user?.email) {
      setAskForDetails(true)
    }
  }, [session])
*/

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenAdminMenu = (event) => {
    setAnchorElAdmin(event.currentTarget);
  };

  const handleOpenModeratorMenu = (event) => {
    setAnchorElModerator(event.currentTarget);
  };

  const handleCloseModeratorMenu = () => {
    setAnchorElModerator(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseAdminMenu = () => {
    setAnchorElAdmin(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleAddBusiness = () => {
    if (userData?.community?.alias) {
      router.push(createBusinesRoute(userData?.community?.alias));
    } else {
      setOpenSendToLoginModal(true);
    }
  };

  const handleNavigation = (page) => {
    console.log("Page:", page)
    switch (page) {
      case "Login":
        router.push(URL_LOGIN);
        break;

      case "Logout":
        router.push(URL_LOGOUT);
        break;

      case "Add Business":
        handleAddBusiness();
        break;

      case "Businesses":
        const handleLink = () => {
          if (userData?.community?.alias) {
            router.push(businessListRoute(userData?.community?.alias));
            setQuery("")
          }
        }
        loggedInUserOperationOnly(handleLink)
        break;

      case "Home":
        router.push("/");
        break;

      case "Profile":
        console.log("UserData:", userData)
        if (userData?.verificationStatus == VerificationStatus.noid) {
          setAskForDetails(true)
        }
        else if (userData?.communityId && userData?.alias) {
          console.log("UserData:", userData)
          console.log("CommunityId:", userData?.communityId)
          getCommunityById(userData?.communityId)
            .then(community => router.push(profileRoute(community?.alias, userData?.alias)))
        }
        break;

      default:
        break;
    }
    setAnchorElNav(null);
    setAnchorElUser(null);
  };

  const AdminMenu = ({ mobile = false }) => {
    return (
      <Menu
        id="admin-appbar"
        anchorEl={anchorElAdmin}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElAdmin)}
        onClose={handleCloseAdminMenu}
        sx={{
          display: { xs: mobile ? "block" : "", md: mobile ? "none" : "block" },
        }}
      >
        <MenuItem
          onClick={() => {
            router.push(verifyIdListAdminRoute());
            handleCloseAdminMenu();
          }}
        >
          <Typography textAlign="center">Verify IDs</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            router.push(claimedBusinessListAdminRoute());
            handleCloseAdminMenu();
          }}
        >
          <Typography textAlign="center">Claimed Businesses</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            router.push(enableBusinessListAdminRoute());
            handleCloseAdminMenu();
          }}
        >
          <Typography textAlign="center">Enable Businesses</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            router.push(flaggedReviewListModeratorRoute());
            handleCloseAdminMenu();
          }}
        >
          <Typography textAlign="center">Flagged Reviews</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            router.push(communityAdminRoute());
            handleCloseAdminMenu();
          }}
        >
          <Typography textAlign="center">Community</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            router.push(createCategoryAdminRoute());
            handleCloseAdminMenu();
          }}
        >
          <Typography textAlign="center">Create Category {'>'} Subcategory</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            router.push(recommendedCommunityListAdminRoute());
            handleCloseAdminMenu();
          }}
        >
          <Typography textAlign="center">Recommended Communities</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            router.push(createDataAdminRoute());
            handleCloseAdminMenu();
          }}
        >
          <Typography textAlign="center">Create Data</Typography>
        </MenuItem>
      </Menu>
    );
  };

  const ModeratorMenu = ({ mobile = false }) => {
    return (
      <Menu
        id="moderator-appbar"
        anchorEl={anchorElModerator}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElModerator)}
        onClose={handleCloseModeratorMenu}
        sx={{
          display: { xs: mobile ? "block" : "", md: mobile ? "none" : "block" },
        }}
      >
        <MenuItem
          onClick={() => {
            router.push(flaggedReviewListModeratorRoute());
            handleCloseModeratorMenu();
          }}
        >
          <Typography textAlign="center">Flagged Reviews</Typography>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        height: { md: "96px" },
        justifyContent: "center"
      }}
    >
      <Container maxWidth="98%"
        sx={{
          textTransform: "uppercase",
          background: "#1976d2"
        }}
      >
        <Toolbar disableGutters>
          <Grid
            container
            flexDirection={"column"}
            alignItems={"center"}
            sx={{
              display: { xs: "none", md: "flex" },
            }}
            width={"150px"}
            marginRight="3px"
          >
            <Grid item>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  fontWeight: 700,
                  color: "#fff",
                  textDecoration: "none",
                  fontSize: '18px',
                  lineHeight: '18px',
                  letterSpacing: "2.88px",
                }}
              >
                CheckMeOut
              </Typography>
              {svgLogo()}
            </Grid>
          </Grid>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ padding: '0', }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {!isAuthenticated
                ? pages
                  .filter((page) => {
                    return (
                      page !== "Administrator"
                      && page !== "Moderator"
                      && page !== "Profile"
                      && page !== "Logout"
                    );
                  })
                  .map((page) => {
                    return (
                      <MenuItem
                        key={page}
                        onClick={() => handleNavigation(page)}
                      >
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    );
                  })
                : pages
                  .filter((page) => {
                    return page !== "Login";
                  })
                  .map((page) => {
                    const AdminMenu = isAdmin && user && (
                      <MenuItem
                        key={page}
                        onClick={handleOpenAdminMenu}
                        aria-controls="admin-appbar"
                        aria-haspopup="true"
                      >
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    );

                    const ModeratorMenu = isModerator && user && (
                      <MenuItem
                        key={page}
                        aria-controls="moderator-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenModeratorMenu}
                      >
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    );

                    return page === "Administrator" ? (
                      AdminMenu
                    ) : page === "Moderator" ? ModeratorMenu : (
                      <MenuItem
                        key={page}
                        onClick={() => handleNavigation(page)}
                      >
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    );
                  })}
            </Menu>
            <AdminMenu mobile={true} />
            <ModeratorMenu mobile={true} />
          </Box>
          <Box
            sx={{
              display: {
                xs: "flex", md: "none",
              },
              width: '100%',
            }}
          >
            <Box
              flexDirection={"column"}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                paddingLeft: '1em',
                overflow: 'hidden',
              }}
              marginRight="3px"
            >
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  fontWeight: 700,
                  textDecoration: "none",
                  fontSize: '18px',
                  lineHeight: '18px',
                  letterSpacing: "2.88px",
                  color: '#fff',
                }}
              >
                CheckMeOut
              </Typography>
              <Image
                src={"/images/logo.png"}
                width={120}
                height={20}
                alt="logo"
                unoptimized={true}
                loader={myLoader}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",

              }}
            >
              <ExpandableSearch expand={false} />
            </Box>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "none",
                md: "flex",
                justifyContent: "flex-end",
              },
              mr: 2,
            }}
          >
            <Box flexGrow={1} maxWidth={400}>
              <Box width={400} display="flex" justifyContent="center" alignItems="center">
                {/* <SearchBar /> */}
                <ExpandableSearch expand={true} />
              </Box>
            </Box>
            {!isAuthenticated
              ? pages
                .filter((page) => {
                  return page !== "Administrator"
                    && page !== "Moderator"
                    && page !== "Profile"
                    && page !== "Logout"
                })
                .map((page) => {
                  return (
                    <MenuItem
                      key={page}
                      onClick={() => handleNavigation(page)}
                    >
                      <Typography
                        textAlign="center"
                        fontWeight={600}
                        letterSpacing={'1px'}
                        sx={{ fontSize: "12px", lineHeight: '20px', }}
                      >
                        {page}
                      </Typography>
                    </MenuItem>
                  );
                })
              : pages
                .filter((page) => {
                  return page !== "Login";
                })
                .map((page) => {
                  const AdminMenu = isAdmin && user && (
                    <MenuItem
                      key={page}
                      onClick={handleOpenAdminMenu}
                      aria-controls="admin-appbar"
                      aria-haspopup="true"
                    >
                      <Typography
                        textAlign="center"
                        fontWeight={600}
                        letterSpacing={'1px'}
                        sx={{ fontSize: "12px", lineHeight: '20px', }}
                      >
                        {page}
                      </Typography>
                    </MenuItem>
                  );

                  const ModeratorMenu = isModerator && user && (
                    <MenuItem
                      key={page}
                      aria-controls="moderator-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenModeratorMenu}
                    >
                      <Typography textAlign="center" fontWeight={600}
                        letterSpacing={'1px'}
                        sx={{ fontSize: "12px", lineHeight: '20px', }}>{page}</Typography>
                    </MenuItem>
                  );

                  return page === "Administrator" ? (
                    AdminMenu
                  ) : page === "Moderator" ?
                    ModeratorMenu
                    : (
                      <MenuItem
                        key={page}
                        onClick={() => handleNavigation(page)}
                      >
                        <Typography
                          textAlign="center"
                          fontWeight={600}
                          letterSpacing={'1px'}
                          sx={{ fontSize: "12px", lineHeight: '20px', }}
                        >
                          {page}
                        </Typography>
                      </MenuItem>
                    );
                })}
            <AdminMenu />
            <ModeratorMenu />
          </Box>

          {isAuthenticated && (
            <Box sx={{ flexGrow: 0 }}>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => handleNavigation(setting)}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
          <CheckLoggedIn
            openModal={openSendToLoginModal}
            closeModal={setOpenSendToLoginModal}
          />
        </Toolbar>
      </Container>
    </AppBar >
  );
}
export default NavBar;

function svgLogo() {
  return (
    <Box sx={{ textAlign: "center" }}>
      <svg
        width="104"
        height="20"
        viewBox="0 0 104 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M99.9784 17.2506C100.123 17.716 99.5844 18.0923 99.1973 17.7963L94.9707 14.5653C94.7915 14.4283 94.5427 14.4283 94.3634 14.5653L90.1369 17.7963C89.7497 18.0923 89.211 17.716 89.3558 17.2506L91.0038 11.9515C91.0662 11.7506 90.9963 11.5322 90.8289 11.4049L86.515 8.1264C86.1338 7.83663 86.3387 7.22832 86.8176 7.22832H92.0937C92.313 7.22832 92.5068 7.08534 92.5714 6.87572L94.1893 1.63186C94.3343 1.16173 94.9998 1.16173 95.1448 1.63186L96.7627 6.87572C96.8274 7.08534 97.0211 7.22832 97.2405 7.22832H102.516C102.995 7.22832 103.2 7.83659 102.819 8.12638L98.5052 11.4049C98.3378 11.5322 98.2679 11.7506 98.3303 11.9515L99.9784 17.2506Z"
          fill="url(#paint0_linear_1_634)"
        />
        <path
          d="M14.645 17.4174C14.7898 17.8827 14.2511 18.2591 13.8639 17.9631L9.63739 14.732C9.45813 14.595 9.20931 14.595 9.03006 14.732L4.80353 17.9631C4.41635 18.2591 3.87769 17.8827 4.02242 17.4174L5.67043 12.1182C5.73289 11.9174 5.66299 11.6989 5.49553 11.5717L1.18168 8.29315C0.800407 8.00338 1.00533 7.39507 1.48423 7.39507H6.76032C6.97968 7.39507 7.17343 7.25208 7.2381 7.04247L8.85594 1.79861C9.00099 1.32848 9.66645 1.32848 9.8115 1.7986L11.4293 7.04247C11.494 7.25208 11.6878 7.39507 11.9071 7.39507H17.1826C17.6615 7.39507 17.8664 8.00334 17.4852 8.29313L13.1719 11.5717C13.0044 11.6989 12.9345 11.9174 12.997 12.1182L14.645 17.4174Z"
          fill="url(#paint1_linear_1_634)"
        />
        <path
          d="M35.9784 18.0839C36.1231 18.5492 35.5845 18.9256 35.1973 18.6296L30.9708 15.3985C30.7915 15.2615 30.5427 15.2615 30.3634 15.3985L26.1369 18.6296C25.7497 18.9256 25.2111 18.5492 25.3558 18.0839L27.0038 12.7847C27.0663 12.5839 26.9964 12.3654 26.8289 12.2382L22.5151 8.95965C22.1338 8.66988 22.3387 8.06157 22.8176 8.06157H28.0937C28.3131 8.06157 28.5068 7.91859 28.5715 7.70898L30.1893 2.46511C30.3344 1.99498 30.9998 1.99498 31.1449 2.46511L32.7627 7.70898C32.8274 7.91859 33.0211 8.06157 33.2405 8.06157H38.516C38.9949 8.06157 39.1998 8.66985 38.8185 8.95963L34.5053 12.2382C34.3378 12.3654 34.2679 12.5839 34.3304 12.7847L35.9784 18.0839Z"
          fill="url(#paint2_linear_1_634)"
        />
        <path
          d="M57.3117 18.4174C57.4564 18.8827 56.9178 19.2591 56.5306 18.9631L52.3041 15.732C52.1248 15.595 51.876 15.595 51.6967 15.732L47.4702 18.9631C47.083 19.2591 46.5444 18.8827 46.6891 18.4174L48.3371 13.1182C48.3996 12.9174 48.3297 12.6989 48.1622 12.5717L43.8484 9.29315C43.4671 9.00338 43.672 8.39507 44.1509 8.39507H49.427C49.6464 8.39507 49.8401 8.25208 49.9048 8.04247L51.5226 2.79861C51.6677 2.32848 52.3331 2.32848 52.4782 2.7986L54.096 8.04247C54.1607 8.25208 54.3544 8.39507 54.5738 8.39507H59.8493C60.3282 8.39507 60.5331 9.00334 60.1519 9.29313L55.8386 12.5717C55.6711 12.6989 55.6012 12.9174 55.6637 13.1182L57.3117 18.4174Z"
          fill="url(#paint3_linear_1_634)"
        />
        <path
          d="M78.6451 17.7506C78.7898 18.216 78.2511 18.5923 77.8639 18.2963L73.6374 15.0653C73.4582 14.9283 73.2093 14.9283 73.0301 15.0653L68.8036 18.2963C68.4164 18.5923 67.8777 18.216 68.0225 17.7506L69.6705 12.4515C69.7329 12.2506 69.663 12.0322 69.4956 11.9049L65.1817 8.6264C64.8004 8.33663 65.0054 7.72832 65.4843 7.72832H70.7603C70.9797 7.72832 71.1735 7.58534 71.2381 7.37572L72.856 2.13186C73.001 1.66173 73.6665 1.66173 73.8115 2.13186L75.4294 7.37572C75.494 7.58534 75.6878 7.72832 75.9072 7.72832H81.1826C81.6615 7.72832 81.8664 8.33659 81.4852 8.62638L77.1719 11.9049C77.0045 12.0322 76.9346 12.2506 76.997 12.4515L78.6451 17.7506Z"
          fill="url(#paint4_linear_1_634)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_634"
            x1="94.6667"
            y1="0.083252"
            x2="94.6667"
            y2="18.7499"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.40625" stopColor="#FFFECF" />
            <stop offset="1" stopColor="#FABC1F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1_634"
            x1="9.33333"
            y1="0.25"
            x2="9.33333"
            y2="18.9167"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.40625" stopColor="#FFFECF" />
            <stop offset="1" stopColor="#FABC1F" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1_634"
            x1="30.6667"
            y1="0.916504"
            x2="30.6667"
            y2="19.5832"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.40625" stopColor="#FFFECF" />
            <stop offset="1" stopColor="#FABC1F" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1_634"
            x1="52"
            y1="1.25"
            x2="52"
            y2="19.9167"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.40625" stopColor="#FFFECF" />
            <stop offset="1" stopColor="#FABC1F" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1_634"
            x1="73.3334"
            y1="0.583252"
            x2="73.3334"
            y2="19.2499"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.40625" stopColor="#FFFECF" />
            <stop offset="1" stopColor="#FABC1F" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
