"use client";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingPage() {
  return (
    <Grid
      container
      sx={{ height: "100%" }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid item>
        <CircularProgress color="secondary" />
      </Grid>
    </Grid>
  );
}
