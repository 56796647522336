import React, { useState, useRef, useEffect } from 'react';
import { useRouter, usePathname } from "next/navigation";
import { Box, IconButton, InputBase, ClickAwayListener, Grow, useMediaQuery } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchQuery } from "@/app/context/searchQueryContext";
import { useUserData } from "@/app/context/userDetailContext";
import { businessListRoute } from "@/app/routes/routes";


export const createSearchTerms = /* GraphQL */ `
  mutation CreateSearchTerms(
    $input: CreateSearchTermsInput!
    $condition: ModelSearchTermsConditionInput
  ) {
    createSearchTerms(input: $input, condition: $condition) {
      id
      __typename
    }
  }
`;


export default function ExpandableSearch({ expand = false, placeholder = 'Search...' }) {
   const router = useRouter()
   const theme = useTheme();
   let pathname = usePathname()
   const { query, setQuery } = useSearchQuery()
   const { userData, authStatus, userAuthMode, loggedInUserOperationOnly } = useUserData()
   const [searchQuery, setSearchQuery] = useState("")
   const [isExpanded, setIsExpanded] = useState(expand)
   const inputRef = useRef(null)
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

   useEffect(() => {
      setIsExpanded(expand);
   }, [expand]);

   useEffect(() => {
      if (isExpanded && inputRef.current) {
         inputRef.current.focus();
      }
   }, [isExpanded]);

   const handleSearchClick = () => {
      if (!expand) {
         setIsExpanded(true);
      }
   };

   const handleClickAway = () => {
      if (!expand) {
         setIsExpanded(false);
      }
   };

   const handleSubmit = async (e) => {
      if (authStatus === "authenticated") {
         await createSearchTermsData()
      }

      setQuery(searchQuery);
      if (pathname === businessListRoute(userData?.community?.alias)) {
         //no action
      } else {
         router.push(businessListRoute(userData?.community?.alias));
         return;
      }

      if (!expand) {
         setIsExpanded(false);
      }
   };

   const handleSubmitIfLoggedIn = (e) => {
      e.preventDefault();
      loggedInUserOperationOnly(handleSubmit)
   }

   const createSearchTermsData = async () => {
      try {
         const res = await API.graphql({
            query: createSearchTerms,
            authMode: userAuthMode,
            variables: {
               input: {
                  userId: userData?.ownerId, communityId: userData?.userMetaDataCommunityId, searchQuery: searchQuery,
               },
            },
         })
      } catch (error) {
         console.error("error", error);
      }
   }

   useEffect(() => {
      if ((pathname !== businessListRoute(userData?.community?.alias)) && query) {
         router.push(businessListRoute(userData?.community?.alias));
         setSearchQuery(query)
      }
      if ((pathname === businessListRoute(userData?.community?.alias)) && !query) {
         setSearchQuery("")
      }
   }, [query]);

   useEffect(() => {
      if (pathname === businessListRoute(userData?.community?.alias)) {
         setQuery(prev => {
            if (!searchQuery && prev) {
               return ""
            }
            return prev
         })
      }
   }, [searchQuery, setQuery]);

   const searchWidth = isMobile ? 150 : 250;

   return (
      <ClickAwayListener onClickAway={handleClickAway}>
         <Box sx={{ position: 'relative', width: isExpanded ? searchWidth : 40, transition: 'width 0.3s ease-in-out' }}>
            {!isExpanded &&
               <IconButton
                  color="inherit"
                  aria-label={isExpanded ? "submit search" : "expand search"}
                  onClick={isExpanded ? handleSubmit : handleSearchClick}
                  sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}
               >
                  <SearchIcon />
               </IconButton>
            }
            <Grow in={isExpanded} style={{ transformOrigin: 'right' }}>
               <Box
                  component="form"
                  onSubmit={handleSubmitIfLoggedIn}
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     bgcolor: (theme) => alpha(theme.palette.background.paper, 0.9),
                     borderRadius: 30,
                     boxShadow: 3,
                     pl: 2,
                     pr: 1,
                     height: 44,
                     transition: 'all 0.3s ease-in-out',
                     '&:hover': {
                        boxShadow: 6,
                        bgcolor: 'background.paper',
                     },
                  }}
               >
                  <InputBase
                     inputRef={inputRef}
                     placeholder={placeholder}
                     sx={{
                        width: '100%',
                        '& input': {
                           transition: 'all 0.3s ease-in-out',
                           fontSize: isExpanded ? 16 : 0,
                        },
                     }}
                     inputProps={{ 'aria-label': 'search' }}
                     onChange={(e) => setSearchQuery(e.target.value)}
                     value={searchQuery}
                  />
                  <IconButton
                     color="primary"
                     aria-label={isExpanded ? "submit search" : "expand search"}
                     onClick={isExpanded ? handleSubmit : handleSearchClick}
                     sx={{
                        p: '10px',
                        transition: 'all 0.3s ease-in-out',
                        transform: isExpanded ? 'scale(1)' : 'scale(0.8)',
                     }}
                  >
                     <SearchIcon />
                  </IconButton>
               </Box>
            </Grow>
         </Box>
      </ClickAwayListener>
   );
}
