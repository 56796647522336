import { createContext, useState, useContext } from "react";

export const SearchQueryContext = createContext({});


export default function SearchQueryProvider({ children }) {
    const [query, setQuery] = useState("")

    return (
        <SearchQueryContext.Provider value={{ query, setQuery }}>
            {children}
        </SearchQueryContext.Provider>
    );
}
export const useSearchQuery = () => useContext(SearchQueryContext);