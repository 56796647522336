import { useEffect, useState } from "react"
import { VerificationStatus } from '@prisma/client';
import Alert from '@mui/material/Alert'
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import InputAdornment from "@mui/material/InputAdornment"
import CircularProgress from '@mui/material/CircularProgress'
import { useForm, Controller } from "react-hook-form"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import { Radio, RadioGroup, FormControlLabel } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import { useUserData } from "@/app/context/userDetailContext"
import { useNotification } from "@/app/context/useNotificationContext"
import { validateLength } from "@/app/utils/validateLength"
import Autocomplete from "@mui/material/Autocomplete"
import IconButton from "@mui/material/IconButton"
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined"
import Avatar from "@mui/material/Avatar"
import resizeImage, { makeSquareImage } from "@/app/utils/ImageResize"
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from "@mui/material/Tooltip"
import { USERTYPE_USER, USERTYPE_BUSINESS } from "@/lib/constants"
import AlertModal from "@/app/components/ui-components/AlertModal"
import { useRouter } from "next/navigation"
import { useSession } from "next-auth/react"
import { AUTHENTICATED, DB_ERROR, DB_SUCCESS, URL_IMAGE } from "@/lib/constants"
import { query } from "@/lib/query"
import { buildAvatarImageUrl, buildIdImageUrl, convertImageToBase64 } from "@/lib/utils"
import { getCommunities } from "@/db/community"
import { getUserByAlias, updateUser } from "@/db/user"
import { deleteObject, uploadWithRetry } from "@/lib/s3"

const formFields = [
  "id",
  "idImage",
  "userType",
  "businessName",
  "firstName",
  "lastName",
  "alias",
  "communityId",
  "village",
  "avatar",
]

export const revalidate = 0
export const dynamic = 'force-dynamic'

function AdditionalDataModalInternal() {
  const [communityList, setCommunityList] = useState([])
  const { notify } = useNotification()
  const [isUpdatingData, setIsUpdatingData] = useState(false)
  const [avatarImage, setAvatarImage] = useState(null)
  const [showIdInfoModal, setShowIdInfoModal] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [idImage, setIdImage] = useState(null)
  const [isUser, setIsUser] = useState(true)
  const [alias, setAlias] = useState(null)
  const [isBusiness, setIsBusiness] = useState(false)
  const [theVillages, setTheVillages] = useState(null)
  const [oldAvatarImage, setOldAvatarImage] = useState(null)
  const session = useSession()

  const {
    userData,
    isEditMode,
    askForDetails,
    isAdmin,
    user,
    setIsEditMode,
    setAskForDetails,
    userAuthMode,
  } = useUserData()

  const { handleSubmit, control, getValues, reset, setValue, watch, setError, register } = useForm()
  const router = useRouter()
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  const handleLogout = async () => {
    setShowLogoutModal(true)
    await delay(4000)
    router.push("/logout")
  }

  const getCommunityList = async () => {
    const { data, success, error } = await query(getCommunities)
    if (data) {
      setCommunityList(data)

      if (!!userData?.communityId) {
        console.log("CommunityData: ", data)
        const communityDetail = data?.find((item) => item.id === userData?.communityId)
        console.log("communityDetail:", communityDetail)
        console.log("userData:", userData)
        setValue('communityId', communityDetail)
      }
      else {
        // Get the Villages community data
        const villagesCommunity = data.find(community =>
          community.alias?.toLowerCase() === "thevillages"
        )
        console.log("TheVillages:", villagesCommunity)
        setValue('communityId', villagesCommunity)
        setTheVillages(villagesCommunity)
      }
    } else {
      notify({
        message: "Error getting community list",
        type: DB_ERROR,
        error: error,
      })
    }
  }

  const getDefaultCommunityForProfile = (community) => {
    if (community) {
      return {
        "id": community?.id,
        "name": community?.name,
        "disable": community?.disable,
        "requiresVerification": community?.requiresVerification
      }
    } else {
      return
    }
  }

  useEffect(() => {
  }, [])

  useEffect(() => {
    if (isEditMode || askForDetails) {
      getCommunityList()
    }

    //console.log("userData***:", userData)
    if (userData?.avatar) {
      setAvatarImage(userData?.avatar)
      console.log("userData?.avatar:", userData?.avatar)
    }

    if (!isEditMode) {
      reset()
    }

  }, [isEditMode, askForDetails])

  useEffect(() => {
    //console.log("IsEditMode", isEditMode)
    //console.log("userData", userData)
    //console.log("communityList", communityList)
    if ((isEditMode || askForDetails) && userData) {
      formFields.forEach((field) => {
        if (field !== "userType") {
          if (!(field === "communityId")) {
            if (userData[field] == null) {
              console.log("field:", field, " was null")
              setValue(field, "")
            }
            else {
              console.log("field:", field, " set to ", userData[field])
              setValue(field, userData[field])
              if (field == 'avatar') {
                setOldAvatarImage(userData[field])
              }
            }
          }
        }
      })

      if (userData?.avatar) {
        setAvatarImage(userData?.avatar)
      }

      /*
      console.log("userData?.userType:", userData?.userType)
      if (userData?.userType === USERTYPE_USER) {
        if (!isUser) {
          setIsUser(true)
          setIsBusiness(false)
        }
      }
      else {
        if (isUser) {
          setIsUser(false)
          setIsBusiness(true)
        }
      }
      */
    }
  }, [isEditMode, askForDetails, userData, getValues, setValue, isUser])

  useEffect(() => {
    console.log("userType:", watch('userType'))
    const userType = watch('userType');
    if (userType === USERTYPE_USER) {
      setIsUser(true)
      setIsBusiness(false)
    }
    else {
      setIsUser(false)
      setIsBusiness(true)
    }
  }, [watch('userType')]);

  const formSubmit = async () => {
    const formData = getValues()
    formData.email = user?.email

    const checkIfAliasExist = async () => {
      const { data, error } = await query(getUserByAlias, formData?.alias, user?.id)
      if (data) {
        throw Error("AliasAlreadyExist")
      }
      else {
        if (error) {
          notify({
            message: "Error verifying alias uniqueness",
            type: DB_ERROR,
            error: error,
          })
        }
        else {
          return data
        }
      }
    }

    try {
      setIsUpdatingData(true)
      if (userData?.alias !== formData?.alias.toLowerCase()) {
        await checkIfAliasExist()
      }

      // If the formData.userType wasn't defined then set it to USERTYPE_USER
      if (!!formData.userType === false) {
        formData.userType = USERTYPE_USER
      }

      if (avatarImage && typeof avatarImage === "object") {
        const avatarImageName = buildAvatarImageUrl(formData?.communityId?.alias, userData?.id.substring(0, 10))
        //formData.avatar = `${URL_IMAGE}/${avatarImageName}`
        console.log("FormData:", formData)
        if (typeof avatarImage === "object") {
          const resizeAvatarImage = await makeSquareImage({
            file: avatarImage,
            fileName: avatarImageName,
            width: 125
          })

          // Delete old avatar
          if (oldAvatarImage && oldAvatarImage.startsWith(URL_IMAGE)) {
            const avatarImageKey = oldAvatarImage.replace(`${URL_IMAGE}/`, '')
            console.log("invalidateR2Cache:", avatarImageKey)
            await deleteObject(avatarImageKey)
          }

          // Upload new avatar
          const imageData = await convertImageToBase64(resizeAvatarImage.image);
          console.log("UPLOADINGi avatarImageName:", avatarImageName)
          await uploadWithRetry(avatarImageName, imageData, resizeAvatarImage?.type)

          formData.avatar = `${URL_IMAGE}/${avatarImageName}`
        }
      }

      if (idImage && typeof idImage === "object") {
        console.log("FormData:", formData)
        const idImageName = buildIdImageUrl(formData?.communityId?.alias, userData?.id.substring(0, 10))
        const resizeIdImage = await resizeImage({
          file: idImage,
          fileName: idImageName,
          maxLongestSide: 800
        })

        try {
          const imageIdData = await convertImageToBase64(resizeIdImage.image);
          await uploadWithRetry(idImageName, imageIdData, idImage?.type)
          formData.idImage = `${URL_IMAGE}/${idImageName}`
          formData.verificationStatus = VerificationStatus.notverified
        } catch (error) {
          console.error('Error uploading file:', error)
          formData.idImage = null
        }
      }
      else {
        formData.idImage = null
        formData.verificationStatus = VerificationStatus.noid
      }

      const ud = generateCreateDataReqParameters(formData, userData)
      const { data, success, error } = await query(updateUser, ud)

      if (success) {
        const profilePath = `/${userData?.communityId?.alias}/profile/${userData?.alias}`
        if (window.location.pathname !== profilePath) {
          window.location.href = "/"
        }

        notify({
          message: "Sucessfully saved profile",
          type: DB_SUCCESS,
        })

        reset()
        setIsEditMode(false)
        setAskForDetails(false)
        setAvatarImage(null)
        setIdImage(null)
      }
      else if (error) {
        notify({
          message: "Error saving profile",
          type: DB_ERROR,
          error: error,
        })
      }
    } catch (error) {
      console.error("Error formData", formData)
      console.error("Error:", error)
      if (error?.message === "AliasAlreadyExist") {
        notify({
          type: "error",
          message: "Alias already exist."
        })
      } else {
        notify({
          message: "Something went wrong :(",
          type: "error",
          error: error,
        })
      }

    } finally {
      setIsUpdatingData(false)
      if (!isEditMode) {
      }
      else {
        notify({
          message: "Data Saved!",
          type: "success",
        })
      }
    }
  }

  const handleEditModeClose = () => {
    reset()
    setIsEditMode(false)
    setAvatarImage(null)
    setIdImage(null)
  }


  return (
    <Dialog open={isEditMode || askForDetails}>
      <DialogContent>
        {isUpdatingData
          ? <Typography textAlign={"center"}>
            <CircularProgress />

          </Typography>
          : <Grid container justifyContent={"center"}>
            <Grid>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                mt={2}
              >
                <Typography variant="h4" component="h1">
                  Additional Information
                </Typography>
                <Typography variant="h6" component="h1" style={{ paddingBottom: '1em' }}>
                  {user?.email}
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit(formSubmit)}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <div className="centered-container">

                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                      What type of account do you want to create?
                    </Typography>

                    {console.log("IsUser:", isUser, "IsBusiness:", isBusiness)}
                    <Controller
                      control={control}
                      name="userType"
                      defaultValue={USERTYPE_USER}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => (
                        <RadioGroup
                          value={value}
                          row={true}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        >
                          <FormControlLabel
                            value={USERTYPE_USER}
                            control={<Radio />}
                            label="User"
                            disabled={isEditMode}
                          />
                          <FormControlLabel
                            value={USERTYPE_BUSINESS}
                            control={<Radio />}
                            label="Business"
                            disabled={isEditMode}
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                  {userData?.verificationStatus == VerificationStatus.noid &&
                    userData?.firstName && userData?.lastName && userData?.alias && userData?.communityId && (
                      <Alert severity="error">
                        Please upload a photo of your Villager ID card, down below, and save your profile.
                        Read the steps below to understand why we are asking you to do this.
                      </Alert>
                    )
                  }
                  {!isUser ?
                    <Controller
                      name="businessName"
                      id="businessName"
                      control={control}
                      defaultValue={""}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <TextField
                            margin="normal"
                            fullWidth
                            onBlur={onBlur}
                            inputRef={register("businessName").ref}
                            value={value}
                            disabled={isEditMode}
                            onChange={onChange}
                            error={!!error}
                            label="Business Name *"
                            autoFocus
                            helperText={error ? error.message : null}
                          />
                        )
                      }}
                      rules={{
                        validate: {
                          max: (value) =>
                            validateLength(value.length, "max", 80) ||
                            "Business Name should be less than 80 characters",
                          min: (value) =>
                            validateLength(value.length, "min", 3) ||
                            "Business Name should be greater than 3 characters",
                        },
                      }}
                    />
                    : <></>
                  }

                  <Controller
                    name="firstName"
                    id="firstName"
                    control={control}
                    defaultValue={""}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          onBlur={onBlur}
                          inputRef={register("firstName").ref}
                          disabled={isEditMode}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          label="First Name"
                          autoFocus
                          helperText={error ? error.message : null}
                        />
                      )
                    }}
                    rules={{
                      required: "First Name is required",
                      validate: {
                        max: (value) =>
                          validateLength(value.length, "max", 80) ||
                          "First Name should be less than 80 characters",
                        min: (value) =>
                          validateLength(value.length, "min", 2) ||
                          "First Name should be greater than 2 characters",
                      },
                    }}
                  />
                  <Controller
                    name="lastName"
                    id="lastName"
                    control={control}
                    defaultValue={""}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          inputRef={register("lastName").ref}
                          disabled={isEditMode}
                          onBlur={onBlur}
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          label="Last Name"
                          helperText={error ? error.message : null}
                        />
                      )
                    }}
                    rules={{
                      required: "Last name is required",
                      validate: {
                        max: (value) =>
                          validateLength(value.length, "max", 80) ||
                          "Last name should be less than 80 characters",
                        min: (value) =>
                          validateLength(value.length, "min", 2) ||
                          "Last name should be greater than 2 characters",
                      },
                    }}
                  />

                  <AliasField control={control} userAuthMode={userAuthMode} getValues={getValues} isEditMode={isEditMode} setAlias={setAlias} id={user?.id} />
                  <Controller
                    name="communityId"
                    control={control}
                    defaultValue={getDefaultCommunityForProfile(userData?.community) || ""}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Autocomplete
                          key={"autocomplete-key"}
                          disablePortal
                          ref={register("communityId").ref}
                          disableClearable
                          value={(value?.name && value) || null}
                          id="combo-box"
                          options={communityList}
                          disabled={isEditMode}
                          onChange={(e, v) => onChange(v)}
                          getOptionLabel={(community) => community.name}
                          isOptionEqualToValue={(community, value) => community.id === value.id}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.name}>
                                {option.name}
                              </li>
                            )
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="normal"
                              fullWidth
                              label="Community"
                              error={!!error}
                              required
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      )
                    }}
                    rules={{
                      required: "Community is required",
                    }}
                  />

                  {isUser ?
                    <Controller
                      name="village"
                      id="village"
                      control={control}
                      defaultValue={""}
                      render={({
                        field: { onChange, onBlur, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <TextField
                            margin="normal"
                            fullWidth
                            inputRef={register("village").ref}
                            onBlur={onBlur}
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            label="Village you live in"
                            helperText={error ? error.message : null}
                          />
                        )
                      }}
                    />
                    : <></>
                  }

                  <Box />

                  <IdVerification
                    verificationStatus={userData?.verificationStatus}
                    existingIdImage={userData?.idImage}
                    communityRequiresVerification={getValues("communityId")?.requiresVerification}
                    setIdImage={setIdImage}
                    setShowIdInfoModal={setShowIdInfoModal}
                    idImage={idImage}
                    userType={userData?.userType}
                    isUser={isUser}
                  />
                  <Box display={"flex"}>
                    <Avatar
                      src={typeof avatarImage === 'string' || avatarImage === null ? avatarImage : URL.createObjectURL(avatarImage)}
                      sx={{
                        borderRadius: '8px',
                        boxShadow: '0 6px 10px rgba(0, 0, 0, 0.1)',
                        height: '125px',
                        width: '125px',
                        border: ' 3px solid #FFF',
                        background: 'orange',
                        overflow: 'hidden',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                      alt={alias ? alias : userData?.firstName}
                    />
                    <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} padding={"2rem"}>
                      <Button
                        component="label"
                        variant="outlined"
                        startIcon={<FileUploadOutlined />}
                        onChange={(event) => {
                          setAvatarImage(event.target.files[0])
                        }}
                      >
                        Upload Profile Picture
                        <input
                          type="file"
                          accept="image/*"
                          hidden
                        />
                      </Button>
                    </Box>
                  </Box>

                  {isAdmin && (
                    <Controller
                      name="isAdministrator"
                      control={control}
                      defaultValue={isAdmin}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <>
                            <Checkbox
                              disabled
                              id="isAdministrator"
                              checked={value}
                              value={value}
                              onChange={onChange}
                              inputProps={{
                                "aria-label": "Make Admin",
                              }}
                            />
                            Admin
                          </>
                        )
                      }}
                    />
                  )}
                  <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        size="medium"
                      >
                        Save
                      </Button>
                    </Grid>
                    {isEditMode && (
                      <Grid item xs={12} sm={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          size="medium"
                          onClick={handleEditModeClose}
                        >
                          Close
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                {
                  showIdInfoModal && (
                    <AlertModal
                      openModal={showIdInfoModal}
                      closeModal={() => setShowIdInfoModal(false)}
                      text={
                        <Typography>
                          The Villager ID is used to verify you live in the Villages. It will be deleted once you are verified. We do not retain the image beyond verifying your ID.
                        </Typography>
                      }
                      buttons={
                        <Button
                          onClick={() => {
                            setShowIdInfoModal(false)
                          }}
                          sx={{ textTransform: "none" }}
                          autoFocus
                          variant="contained"
                        >
                          Close
                        </Button>
                      } />)
                }
                {
                  showLogoutModal && (
                    <AlertModal
                      openModal={showLogoutModal}
                      closeModal={() => setShowLogoutModal(false)}
                      text={
                        <Typography>
                          Saving your data. Please login again to see the changes.
                        </Typography>
                      }
                      buttons={
                        <Button
                          onClick={() => {
                            setShowLogoutModal(false)
                          }}
                          sx={{ textTransform: "none" }}
                          autoFocus
                          variant="contained"
                        >
                          Close
                        </Button>
                      } />)
                }
              </Box >
            </Grid >
          </Grid >}
      </DialogContent >
    </Dialog >
  )
}

const AdditionalDataModal = () => {
  const session = useSession()
  return session?.status == AUTHENTICATED ? (
    <AdditionalDataModalInternal />
  ) : null
}

export default AdditionalDataModal

function AliasField({ control, userAuthMode, getValues, isEditMode, setAlias, id }) {

  const ALIAS_AVAILABLE = "available"
  const ALIAS_NOT_AVAILABLE = "notAvailable"
  const ALIAS_CHECK_ERROR = "error"
  const NO_SPACE_ALLOWED = "noSpace"

  const [availabilityStatus, setAvailabilityStatus] = useState('')
  const [isChecking, setIsChecking] = useState(false)

  const handleCheckAvailability = async () => {
    const value = getValues("alias")
    if (!shouldNotContainSpace(value)) {
      setAvailabilityStatus(NO_SPACE_ALLOWED)
    } else {
      const { data, error } = await query(getUserByAlias, value, id)
      if (data) {
        setAvailabilityStatus(ALIAS_NOT_AVAILABLE)
      }
      else {
        if (error) {
          console.error('Error checking availability:', error)
          setAvailabilityStatus(ALIAS_CHECK_ERROR)
        }
        else {
          setAvailabilityStatus(ALIAS_AVAILABLE)
        }
      }
    }
  }

  const aliasAvailablityInfo = (availabilityStatus) => {
    switch (availabilityStatus) {
      case ALIAS_AVAILABLE:
        return <Typography variant={"body2"} color={"green"}>Alias is available!</Typography>
      case ALIAS_NOT_AVAILABLE:
        return <Typography variant={"body2"} color={"error"}>Alias is already taken!</Typography>
      case ALIAS_CHECK_ERROR:
        return <Typography variant={"body2"} color={"error"}>An error occurred while checking availability.</Typography>
      case NO_SPACE_ALLOWED:
        return <Typography variant={"body2"} color={"error"}>Alias can not contain spaces, should be a single word.</Typography>
      default:
        return null
    }
  }

  return <>
    <Controller
      name="alias"
      id="alias"
      control={control}
      defaultValue={""}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        return (
          <TextField
            margin="normal"
            fullWidth
            required
            onBlur={onBlur}
            value={value}
            disabled={isEditMode}
            onChange={(e) => {
              setAvailabilityStatus('')
              setAlias(e.target.value)
              onChange(e)
            }}
            error={!!error}
            label="Alias"
            helperText={error ? error.message : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    disabled={isEditMode}
                    onClick={handleCheckAvailability}
                  ><Typography variant="caption" component={"span"}>
                      Check Availability
                    </Typography>
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        )
      }}
      rules={{
        required: "Alias is required",
        validate: {
          max: (value) =>
            validateLength(value.length, "max", 50) ||
            "Alias Name should be less than 50 characters",
          min: (value) =>
            validateLength(value.length, "min", 3) ||
            "Alias Name should be greater than 3 characters",
          noSpace: (value) => shouldNotContainSpace(value) || "No space allowed."

        },
      }}
    />
    {isChecking && <Typography> Checking if alias is available...</Typography>}
    {!isChecking && aliasAvailablityInfo(availabilityStatus)}
  </>
}

function generateCreateDataReqParameters(fd, userData) {
  const verificationStatus = userData?.verificationStatus ? userData.verificationStatus : (fd?.idImage ? VerificationStatus.notverified : VerificationStatus.noid)
  return {
    ...userData,
    id: fd?.id,
    alias: fd?.alias?.toLowerCase(),
    avatar: fd?.avatar,
    businessName: fd?.businessName,
    communityId: fd?.communityId?.id,
    email: fd?.email,
    name: fd?.firstName + " " + fd?.lastName,
    firstName: fd?.firstName,
    lastName: fd?.lastName,
    idImage: fd?.idImage,
    userType: fd?.userType,
    village: fd?.village,
    verificationStatus: fd?.verificationStatus,
  }
}

const updateMetaDetails = /* GraphQL */ `
  mutation UpdateUserMetaData(
    $input: UpdateUserMetaDataInput!
    $condition: ModelUserMetaDataConditionInput
  ) {
    updateUserMetaData(input: $input, condition: $condition) {
    createdAt
    email
    userType
    businessName
    firstName
    lastName
    owner
    ownerId
    updatedAt
    communityId
    village
  }
  }`

function shouldNotContainSpace(value) {
  const containsSpace = !/^\S+$/.test(value)
  return !containsSpace
}

function IdVerification({ communityRequiresVerification, verificationStatus, idImage, existingIdImage, setIdImage, setShowIdInfoModal, userType, isUser }) {
  const [previewUrl, setPreviewUrl] = useState(null)
  const [imageOrientation, setImageOrientation] = useState('landscape')

  useEffect(() => {
    if (idImage && typeof idImage !== 'string') {
      const url = URL.createObjectURL(idImage)
      setPreviewUrl(url)

      // Check image orientation using regular HTMLImageElement
      const img = document.createElement('img')
      img.onload = () => {
        setImageOrientation(img.width >= img.height ? 'landscape' : 'portrait')
      }
      img.src = url

      return () => URL.revokeObjectURL(url)
    }
  }, [idImage])

  return <Box>
    {
      userType === USERTYPE_USER
      && communityRequiresVerification
      && verificationStatus === VerificationStatus.notverified
      && <Typography color="red" sx={{ paddingBottom: '1em' }}>
        Your account and Id is pending verification. Once it has been verified you will be able to fully use CheckMeOut.com.
      </Typography>
    }

    {
      userType === USERTYPE_BUSINESS
      && communityRequiresVerification
      && verificationStatus === VerificationStatus.notverified
      && <Typography color="red" sx={{ paddingBottom: '1em' }}>
        Your account is pending verification. Once it has been verified you will be able to fully use CheckMeOut.com.
      </Typography>
    }

    {
      isUser
      && communityRequiresVerification
      && (!existingIdImage || verificationStatus === VerificationStatus.noid)
      && verificationStatus !== VerificationStatus.verified
      && <>
        <Typography variant="h6" component="h2"
          sx={{
            fontSize: {
              xs: '12px',
              sm: '12px',
              md: '14px',
              lg: '14px',
            },
            textAlign: 'left',
            lineheight: '22px',
            color: 'black',
          }}>
          <p><b>Step 1: Snap a photo of your Village ID.</b> We’re not asking for your passport or a DNA sample (ewww). Just a photo of your Village ID. You know, the one that proves you’re a bona fide resident of The Villages – not just a rogue  with a keyboard.</p>
          <p><b>Step 2: Trust, but Verify.</b> We want to ensure you are a legit neighbor. This ensures you care about your local community. Once we’ve confirmed you are a neighbor, you’re in! No more lurking in the shadows – you’re part of the community now.</p>
          <p><b>Step 3: Real Talk, Real People.</b> Every review, post, and rant you encounter on CheckMeOut is legit. No shady bots, no corporate shills. Just your fellow Villagers sharing their unfiltered thoughts. So when you read that glowing review of the local taco truck, rest assured – it’s not the taco truck owner’s secret alter ego.</p>
          <p><b>Step 4: Upload your ID and get back to living your best life.</b> Once we verify your ID, it vanishes into the digital sunset. We delete it quicker than a Villages golf leaving the squares at 9pm. Your privacy matters - we&apos;re not the ID collectors anonymous club.</p>
          ``        </Typography>

        <Box display="flex">
          <Box
            sx={{
              width: imageOrientation === 'landscape' ? '200px' : '150px',
              height: imageOrientation === 'landscape' ? '150px' : '200px',
              borderRadius: '8px',
              boxShadow: '0 6px 10px rgba(0, 0, 0, 0.1)',
              border: '3px solid #FFF',
              overflow: 'hidden',
              background: '#f5f5f5',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative', // Added for next/image
              marginBottom: '1rem',
            }}
          >
            {previewUrl ? (
              // Using regular img tag for preview since it's a blob URL
              <img
                src={previewUrl}
                alt="ID Preview"
                style={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  width: 'auto',
                  height: 'auto',
                }}
              />
            ) : (
              <FileUploadOutlined sx={{ fontSize: 40, color: '#999' }} />
            )}
          </Box>

          <Box display="flex" justifyContent="center" flexDirection="column" padding="2rem">
            <Button
              component="label"
              variant="outlined"
              startIcon={<FileUploadOutlined />}
              endIcon={
                <Tooltip title="Know more">
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault(); // Prevent button click from triggering file upload
                      setShowIdInfoModal(true);
                    }}
                    size="small"
                  >
                    <InfoIcon color="primary" />
                  </IconButton>
                </Tooltip>
              }
              onChange={(event) => {
                setIdImage(event.target.files[0])
              }}
            >
              {isUser ? "Upload Villager Id" : "Upload Business Photo"}
              <input
                type="file"
                accept="image/*"
                hidden
              />
            </Button>
          </Box>
        </Box>
      </>
    }

    {
      !isUser
      && communityRequiresVerification
      && (!existingIdImage || verificationStatus === VerificationStatus.noid)
      && verificationStatus !== VerificationStatus.verified
      && <>
        <Typography variant="h6" component="h2"
          sx={{
            fontSize: {
              xs: '12px',
              sm: '12px',
              md: '14px',
              lg: '14px',
            },
            textAlign: 'left',
            lineheight: '22px',
            color: 'black',
          }}>
          <p><b>Step 1: Snap a photo that shows you work or own the business.</b> We’re not asking for your business certificate. Just a photo of you at the business, near a work vehicle, your business card, etc. Have fun with it!</p>
          <p><b>Step 2: Trust, but Verify.</b> We want to ensure you are a legit local business owner or serve the community you are selecting. This ensures you care about your local community. Once we’ve confirmed you then you’re in! No more lurking in the shadows – you’re part of the community now.</p>
          <p><b>Step 3: Real Talk, Real People.</b> Every review, post, and rant you encounter on CheckMeOut is legit. No shady bots, no corporate shills. Just your fellow Villagers sharing their unfiltered thoughts. So when you read that glowing review of the local taco truck, rest assured – it’s not the taco truck owner’s secret alter ego.</p>
          <p><b>Step 4: Upload the photo then go play a round of golf.</b> Once your photo gets the thumbs-up, we’ll bid farewell to the photo. We delete it faster than you can say &quot;CheckMeOut!&quot; we’re not here to hoard the photos.</p>
        </Typography>

        <Box display="flex">
          <Box
            sx={{
              width: '125px',
              height: '125px',
              borderRadius: '8px',
              boxShadow: '0 6px 10px rgba(0, 0, 0, 0.1)',
              border: '3px solid #FFF',
              overflow: 'hidden',
              background: '#f5f5f5',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {previewUrl ? (
              <img
                src={previewUrl}
                alt="ID Preview"
                style={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  width: 'auto',
                  height: 'auto',
                }}
              />
            ) : (
              <FileUploadOutlined sx={{ fontSize: 40, color: '#999' }} />
            )}
          </Box>

          <Box display="flex" justifyContent="center" flexDirection="column" padding="2rem">
            <Button
              component="label"
              variant="outlined"
              startIcon={<FileUploadOutlined />}
              endIcon={
                <Tooltip title="Know more">
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault(); // Prevent button click from triggering file upload
                      setShowIdInfoModal(true);
                    }}
                    size="small"
                  >
                    <InfoIcon color="primary" />
                  </IconButton>
                </Tooltip>
              }
              onChange={(event) => {
                setIdImage(event.target.files[0])
              }}
            >
              {isUser ? "Upload Villager Id" : "Upload Business Photo"}
              <input
                type="file"
                accept="image/*"
                hidden
              />
            </Button>
          </Box>
        </Box>
      </>
    }

  </Box>
}