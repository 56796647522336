import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { useRouter } from "next/navigation";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import FacebookIcon from '@mui/icons-material/Facebook';

export default function Footer() {
  const router = useRouter();
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Box sx={{ bgcolor: "white", paddingTop: '30px' }}>
        <Box sx={{ bgcolor: "#F6F9FF" }}>
          <Grid container rowSpacing={2} justifyContent="center" alignItems="flex-start">
            <Grid item xs={1} sm={1} >
            </Grid>
            <Grid item xs={10} sm={4} >
              <Link href="#" sx={{
                fontSize: {
                  xs: '15px',
                  sm: '15px',
                  md: '18px',
                  lg: '18px',
                },
                color: '#343434',
                fontWeight: '600',
                lineHeight: '22px',
                marginBottom: '4px',
                textDecoration: 'none',
              }}>
                About CheckMeOut.com
              </Link>

              <Typography sx={{
                fontSize: '14px',
                color: '#343434',
                fontWeight: '500',
                lineHeight: '22px', marginBottom: '10px', maxWidth: {
                  xs: '100%',
                  sm: '100%',
                  md: '180px',
                  lg: '180px',
                  xl: '180px',
                },
              }}>
                CheckMeOut is where your neighbors spill the real tea (or coffee, depending on your vibe). Verified users, verified reviews. Read what your community&apos;s businesses are all about, write your own epic review, and join the fun!
              </Typography>
              <Stack direction={'row'} spacing={2}>
                <Link href="https://www.facebook.com/groups/156621817711581" target="_blank" sx={{ textDecoration: 'none', }}> <FacebookIcon sx={{ color: '#4B7FCD', }} /></Link>
                { /*
                  <Link href="#" sx={{ textDecoration: 'none', }}>  <YouTubeIcon sx={{ color: '#FF0000', }} /></Link>
                  <Link href="#" sx={{ textDecoration: 'none', }}> <LinkedInIcon sx={{ color: '#4A71FB', }} /></Link>
                  <Link href="#" sx={{ textDecoration: 'none', }}><InstagramIcon sx={{ color: '#ED017F', }} /> </Link>
                  */
                }
              </Stack>
            </Grid>
            <Grid item xs={1} sm>
            </Grid>
            <Grid item xs={1} >
            </Grid>
            <Grid item xs={10} sm={5} >
              <Stack direction={{
                xs: 'column',
                sm: 'column',
                md: 'column',
                lg: 'column',
                xl: 'column',
              }}
                spacing={1}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
              >

                <Link href="#" sx={{
                  fontSize: {
                    xs: '15px',
                    sm: '15px',
                    md: '18px',
                    lg: '18px',
                  },
                  color: '#343434',
                  fontWeight: '600',
                  lineHeight: '22px', marginBottom: '2px',
                  textDecoration: 'none',
                }}>
                  Important Links
                </Link>

                <Box >
                  <Stack direction={'column'} spacing={1}>
                    <Link href="/contactus"
                      sx={{
                        fontSize: '14px',
                        color: '#343434',
                        fontWeight: '500',
                        textDecoration: 'none',
                      }} >
                      Contact Us
                    </Link>
                    <Link href="/advertise_with_us"
                      sx={{
                        fontSize: '14px',
                        color: '#343434',
                        fontWeight: '500',
                        textDecoration: 'none',
                      }} >
                      Advertise with us
                    </Link>
                    <Link href="/recommend-community"
                      sx={{
                        fontSize: '14px',
                        color: '#343434',
                        fontWeight: '500',
                        textDecoration: 'none',
                      }} >
                      Recommend a New Community
                    </Link>
                    <Link href="/policy"
                      sx={{
                        fontSize: '14px',
                        color: '#343434',
                        fontWeight: '500',
                        textDecoration: 'none',
                      }} >
                      Privacy Policy
                    </Link>
                    <Link href="#"
                      sx={{
                        fontSize: '14px',
                        color: '#343434',
                        fontWeight: '500',
                        textDecoration: 'none',
                      }} >
                      FAQs
                    </Link>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={1} sm={1}>
            </Grid>
            <Grid item xs={10}>
              <Stack direction={'row'} spacing={5} sx={{ marginBottom: '20px' }}>
                <Typography sx={{
                  fontSize: '14px',
                  color: '#343434',
                  fontWeight: '400',
                  lineHeight: '22px',
                }} >
                  Copyright © {currentYear} Mark-It, Inc. All rights reserved.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box >
      </Box >
    </>

  );
}
