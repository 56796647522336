export async function query(queryFn, ...params) {
   console.log("query", queryFn, ...params)
   try {
      // Remove updatedAt and createdAt from any object parameters
      const cleanedParams = params.map(param => {
         if (param && typeof param === 'object') {
            const { updatedAt, ...rest } = param;
            return rest;
         }
         return param;
      })

      // const data = await queryFn(param1, param2, param3, param4, param5, param6, param7, param8, param9, param10)
      const data = await queryFn(...cleanedParams)
      return { data, success: true, error: null }
   } catch (error) {
      console.error('Database query error:', error)
      return { data: null, success: false, error: error.message }
   }
}