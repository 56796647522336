import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { useRouter } from "next/navigation";
import { authRoute } from "@/app/routes/routes";

export default function CheckLoggedIn({ openModal, closeModal }) {
  const [open, setOpen] = React.useState(false);
  const router = useRouter();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    closeModal(false);
  };

  React.useEffect(() => {
    if (openModal) handleClickOpen();
  }, [openModal]);

  const handleSignin = () => {
    router.push(authRoute());
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      // PaperProps={{
      //   sx: { position: "fixed", top: "30%", m: "auto" },
      // }}
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              Please Login or Create an Account before trying to Add a Business
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleSignin}
            sx={{ textTransform: "none" }}
            autoFocus
            variant="contained"
          >
            Login / Create Account
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
