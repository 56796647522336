import { useSession } from "next-auth/react"
import { USERTYPE_ADMINISTRATOR } from "@/lib/constants"
import { FOLDER_AVATAR, FOLDER_COMMUNITY, FOLDER_IDS } from "@/lib/constants"

/**
 * Validates an email address.
 * @param {string} email - The email address to validate.
 * @returns {boolean} True if the email is valid, false otherwise.
 */
export function validateEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function IsAdministrator() {
    const session = useSession()
    console.log("Session Administrator: ", session)
    return session?.data?.user?.userType === USERTYPE_ADMINISTRATOR
}

/**
 * Splits a full name into first name and last name.
 * If the name contains more than two words, all but the last word are considered part of the first name.
 * @param {string} fullName - The full name to split (e.g. 'john doe' or 'john a. doe')
 * @returns {Object} Object containing firstName and lastName
 */
export function splitName(fullName) {
    if (!fullName) return { firstName: '', lastName: '' };

    const nameParts = fullName.trim().split(/\s+/);

    if (nameParts.length === 1) {
        return {
            firstName: nameParts[0],
            lastName: ''
        };
    }

    const lastName = nameParts.pop();
    const firstName = nameParts.join(' ');

    return {
        firstName,
        lastName
    };
}

/**
 * Returns the URL of an avatar image stored in R2.
 * @param {string} communityAlias - The alias of the community.
 * @param {string} userId 
 * @returns {string} The URL of the avatar image.
 */
export function buildAvatarImageUrl(communityAlias, userId) {
    return `${FOLDER_COMMUNITY}/${communityAlias}/${FOLDER_AVATAR}/${userId}.jpg`
}

/**
 * Returns the URL of an ID image stored in R2.
 * @param {string} communityAlias - The alias of the community.
 * @param {string} userId 
 * @returns {string} The URL of the ID image.
 */
export function buildIdImageUrl(communityAlias, userId) {
    return `${FOLDER_COMMUNITY}/${communityAlias}/${FOLDER_IDS}/${userId}.jpg`
}

export function isLocalURL(url) {
    return url.includes("cmoimg.com")
}


/**
 * Converts an image file to a base64 string.
 * @param {File} file - The image file to convert.
 * @returns {Promise<string>} A promise that resolves to the base64 string.
 */
export function convertImageToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            // Remove the data:image/jpeg;base64, prefix
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};