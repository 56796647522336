import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import purple from "@mui/material/colors/purple";

const primary = purple[300];

export default function Error() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: primary,
      }}
    >
      <Typography variant="h1" style={{ color: "white" }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: "white" }}>
        The page you’re looking for doesn’t exist.
      </Typography>
      <Button variant="contained">Back Home</Button>
    </Box>
  );
}
